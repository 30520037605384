import React from 'react';

import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { FieldCheckbox } from '../../../components';

import css from './AddOnsFields.module.css';

const { Money } = sdkTypes;

const AddOnsFields = props => {
  const { intl, addOns, formId, unitPrice } = props;

  const formattedAddOn = addOnPrice => {
    return formatMoney(intl, new Money(addOnPrice, unitPrice.currency));
  };

  const addOnLabel = (addOnName, addOnPrice) => {
    return `${addOnName}: ${formattedAddOn(addOnPrice)}`;
  };

  return (
    !!addOns && (
      <div className={css.addOns}>
        {addOns.map((addOn, index) => {
          const { title, price } = addOn;

          return (
            <div className={css.addOn} key={index}>
              <FieldCheckbox
                className={css.feeContainer}
                id={`${formId}addOns${title}`}
                name="addOns"
                label={addOnLabel(title, price)}
                value={title}
              />
            </div>
          );
        })}
      </div>
    )
  );
};

export default AddOnsFields;
