import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { OutsideClickHandler } from '../..';

import css from './GuestInput.module.css';

const UserIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7,-0c-3.86,-0 -7,3.14 -7,7c-0,3.86 3.14,7 7,7c3.86,-0 7,-3.14 7,-7c-0,-3.86 -3.14,-7 -7,-7Zm-0,1.077c3.277,-0 5.923,2.646 5.923,5.923c0,3.277 -2.646,5.923 -5.923,5.923c-3.277,0 -5.923,-2.646 -5.923,-5.923c-0,-3.277 2.646,-5.923 5.923,-5.923Zm-0,1.615c-1.481,0 -2.692,1.212 -2.692,2.693c-0,0.816 0.38,1.541 0.959,2.036c-1.212,0.629 -2.036,1.895 -2.036,3.348l1.077,0c-0,-1.493 1.199,-2.692 2.692,-2.692c1.493,-0 2.692,1.199 2.692,2.692l1.077,0c0,-1.453 -0.824,-2.719 -2.036,-3.348c0.579,-0.495 0.959,-1.22 0.959,-2.036c0,-1.481 -1.211,-2.693 -2.692,-2.693Zm-0,1.077c0.898,0 1.615,0.717 1.615,1.616c0,0.898 -0.717,1.615 -1.615,1.615c-0.898,-0 -1.615,-0.717 -1.615,-1.615c-0,-0.899 0.717,-1.616 1.615,-1.616Z"
      fill="#4a4a4a"
    />
  </svg>
);

const PlusIcon = () => (
  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12H20M12 4V20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MinusIcon = () => (
  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 12L18 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const GuestInputField = ({
  label,
  helpText,
  id,
  decrement,
  increment,
  maximumGuests,
  guestCounts,
}) => (
  <div className={css.guestCountInputWrapper}>
    <label htmlFor={id}>
      {label}
      {helpText && <small>{helpText}</small>}
    </label>
    <button
      type="button"
      className={css.guestCountDecrement}
      onClick={() => decrement(id)}
      disabled={guestCounts[id] === 0}
    >
      <MinusIcon />
    </button>
    <input
      type="text"
      className={css.guestCountNumber}
      id={id}
      name={id}
      value={guestCounts[id]}
      readOnly
    />
    <button
      type="button"
      className={css.guestCountIncrement}
      onClick={() => increment(id)}
      disabled={guestCounts.counter >= maximumGuests}
    >
      <PlusIcon />
    </button>
  </div>
);

const GuestInput = ({ maximumGuests, youngestAge, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [guestCounts, setGuestCounts] = useState({
    adultCount: 1,
    childrenCount: 0,
    infantCount: 0,
    counter: 1,
  });
  const intl = useIntl();

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const guestsLabel = intl.formatMessage({ id: 'GuestInput.label' });
  const adultLabel = intl.formatMessage({ id: 'GuestInput.adultLabel' });
  const childrenLabel = intl.formatMessage({ id: 'GuestInput.childrenLabel' });
  const infantLabel = intl.formatMessage({ id: 'GuestInput.infantLabel' });
  const adultHelpText = intl.formatMessage({ id: 'GuestInput.adultHelpText' });
  const childrenHelpText = intl.formatMessage({ id: 'GuestInput.childrenHelpText' });
  const infantHelpText = intl.formatMessage({ id: 'GuestInput.infantHelpText' });
  const maximumGuestsSummary = intl.formatMessage(
    { id: 'GuestInput.maximumGuestsSummary' },
    { count: maximumGuests }
  );
  const maximumGuestsHelpText = intl.formatMessage(
    { id: 'GuestInput.helpText' },
    { age: youngestAge }
  );

  return (
    <Field {...rest}>
      {({ input }) => {
        const increment = id => {
          if (
            id === 'infantCount' ||
            guestCounts.adultCount + guestCounts.childrenCount < maximumGuests
          ) {
            setGuestCounts(prevState => {
              input.onChange(prevState.counter + 1);
              return {
                ...prevState,
                [id]: prevState[id] + 1,
                counter: prevState.counter + 1,
              };
            });
          }
        };

        const decrement = id => {
          if (guestCounts[id] > 0) {
            setGuestCounts(prevState => {
              input.onChange(prevState.counter - 1);
              return {
                ...prevState,
                [id]: prevState[id] - 1,
                counter: prevState.counter - 1,
              };
            });
          }
        };

        const guestCountSummary = intl.formatMessage(
          { id: 'GuestInput.guestCountSummary' },
          { count: guestCounts.counter }
        );

        return (
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <div className={classNames(css.root, { [css.isOpen]: isOpen })}>
              <label htmlFor="toggle-guest-panel">{guestsLabel}</label>
              <button
                type="button"
                className={css.labelButton}
                id="toggle-guest-panel"
                onClick={toggleIsOpen}
              >
                <div className={css.guestCountDisplay}>
                  <UserIcon />
                  <span>{guestCountSummary}</span>
                </div>
              </button>
              <div className={css.guestCountPanel}>
                <GuestInputField
                  label={adultLabel}
                  helpText={adultHelpText}
                  id="adultCount"
                  decrement={decrement}
                  increment={increment}
                  guestCounts={guestCounts}
                  maximumGuests={maximumGuests}
                />
                <GuestInputField
                  label={childrenLabel}
                  helpText={childrenHelpText}
                  id="childrenCount"
                  decrement={decrement}
                  increment={increment}
                  guestCounts={guestCounts}
                  maximumGuests={maximumGuests}
                />
                <GuestInputField
                  label={infantLabel}
                  helpText={infantHelpText}
                  id="infantCount"
                  decrement={decrement}
                  increment={increment}
                  guestCounts={guestCounts}
                  maximumGuests={maximumGuests}
                />
                <p className={css.guestCountHelp}>
                  {maximumGuestsSummary}
                  <small>{maximumGuestsHelpText}</small>
                </p>
              </div>
            </div>
          </OutsideClickHandler>
        );
      }}
    </Field>
  );
};

export default GuestInput;
